@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --primcolor: #0045db;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

.container {
  padding: 50px 0;
  width: 100%;
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 12px;
}

.title {
  font-size: 52px;
  margin-bottom: 12px;
}

.section-line {
  height: 3px;
  width: 40px;
  background-color: var(--primcolor);
  margin-bottom: 24px;
}

.primcolor {
  color: var(--primcolor);
}

.para {
  font-size: 18px;
}

.scroll {
  position: absolute;
  left: 50%;
}
.scroll {
  bottom: 20px;
  transform: translateX(-50%);
}
.scroll__icon {
  width: 20px;
  height: 30px;
  border: 2px solid #242424;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scroll__icon:after {
  content: "";
  width: 4px;
  height: 6px;
  background-color: #242424;
  border-radius: 2px;
  animation: 1s infinite alternate-reverse scroll;
}
@keyframes scroll {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(-3px);
  }
}

/*

Nav

*/
nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.nav--container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.nav__links {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.nav__links a {
  margin-left: 32px;
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 500;
}

/*

LANDING PAGE

*/
.landing--container {
  min-height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
  align-items: center;
}

.landing--row {
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  width: 940px;
}

.landing--title {
  font-size: 120px;
  position: relative;
}

.landing--para {
  font-size: 24px;
  max-width: 450px;
  line-height: 200%;
}

.landing--title-2::after {
  content: "👋";
  animation: 1s ease-in-out waveAnim;
  position: absolute;
  top: -20px;
}

@keyframes waveAnim {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(0);
  }
}

/* WORK */
.work--row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home__work--slider-container {
  padding: 0 40px;
  width: 100%;
}

.home__work--container {
  background-color: rgba(0, 0, 0, 0.062);
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin: 0 12px;
}

.home__work--video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 1:1 aspect ratio */
}

.home__work--video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.home__work--title {
  font-size: 38px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.home__work--para {
  font-size: 18px;
  font-weight: 400;
}

/*

CONTACT

*/
.contact__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 12px;
}

.contact__link--text {
  margin-left: 8px;
}

/*

WORK

*/
.work--row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.work--container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.work {
  display: flex;
  margin-bottom: 100px;
  flex-direction: column;
  width: 500px;
  margin: 20px;
}

.work--showcase {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 1:1 aspect ratio */
}

.work--showcase iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.work--info {
  max-width: 500px;
  height: 100%;
}

.work--title {
  font-size: 36px;
  margin-bottom: 12px;
  margin-top: 10px;
}

.work--para {
  font-size: 20px;
}
